import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <>
      <SEO title="Home" />
      <div id="title-wrapper">
        <div id="title-container">
          <h1>
            Eightsh <br /> One
          </h1>
        </div>
      </div>
      <div className="pl-3">
        <ul className="tree">
          <li>
            eightsh.one/
            <ul>
              <li>
                dev/
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/eightshone"
                    >
                      github
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                design/
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.behance.net/eightshone"
                    >
                      behance
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/eightshone"
                    >
                      instagram
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  </Layout>
);

export default IndexPage;
